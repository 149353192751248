<template>
  <div class='template'>    
      <transition name="fade" appear>
      <article class="images" :class="[collage.length === 3 ? 'three-images' : '']">
          <div v-for="image in collage" :key="image.title" :style="{backgroundImage:`url(${image.file})`}"/>              
      </article>        
      </transition>
      <article class='text-content'>
            <transition name="slide" appear>
                <div>
                    <h1>{{page.title}}</h1>
                    <Editor @click="activateEditor" plugins="lists" api-key="2uyqbbnykquw7zpsd3ht43lzwe4s4dyu3l8x6p7vgvx8v4ug" :init="{menubar:false}" toolbar='[ undo redo | bold italic underline | fontsize forecolor | bullist numlist link ]' v-model="copy" class="copy" v-if="copy || $store.state.devMode" inline :disabled="!editorFocused" @change="updateContent"/>
                    <AttachmentUI :page="page" :gallery="gallery" @showGallery="galleryVisible = true" @showVideo="showVideo"/>
                </div>
            </transition>  
            <div class="linear-holder">
                <LinearButton :to="page?.previous" previous/>
                <LinearButton :to="page?.next"/>
            </div> 
       </article>         
      <transition name="fade">
        <Gallery :images="gallery" @close="galleryVisible = false" v-show="galleryVisible"/>
      </transition>
      <transition name="fade">
        <Gallery videoMode :images="[]" :videoId="videoId" @close="videoVisible = false" v-if="videoVisible" :show="videoVisible"/>
      </transition>      
  </div>  
</template>

<script>
//<Editor @click="activateEditor" plugins="lists" api-key="2uyqbbnykquw7zpsd3ht43lzwe4s4dyu3l8x6p7vgvx8v4ug" toolbar='[ undo redo | bold italic underline | fontsize forecolor | bullist numlist link ]' v-model="page.copy" class="copy" v-if="page.copy || $store.state.devMode" inline :disabled="!editorFocused" @change="updateContent"/>
import Page from '@/mixins/Page';
import AttachmentUI from '@/components/AttachmentUI';
import LinearButton from '@/components/LinearButton';
import ImageTitle from '@/components/ImageTitle';
import Gallery from '@/components/Gallery';
export default {
    name:'Up To Three Images Left',
    mixins: [Page],
    props: {
        page:Object
    },
    components:{
        AttachmentUI,
        Gallery,
        LinearButton,
        ImageTitle
    },
    data() {
        return {
            imagesFromGallery:2,      
            galleryVisible:false,
            videoVisible:false,             
            copy:this.page.copy  
        }
    },
    computed: {        
        collage() {
            let images = [];
            if(this.page.background) {
                images.push({
                    file:this.page.background,
                    title:this.page.title
                });
            }
            for (let index = 0; index < Math.min(this.imagesFromGallery, this.page.gallery?.length); index++) {
                const element = this.page.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        },        
        gallery() {
            let images = [];        
            //some pages put images from the gallery on screen; for instance the UpToThreeImagesLeft template has up to 3 images visible on the left; 
            //so if the rep does add 3 images; two of which would of been in the gallery in the CMS; then they need to be removed from the gallery
            for (let index = this.imagesFromGallery; index < this.page.gallery?.length; index++) {
                const element = this.page.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        }
    }
}
</script>

<style lang="scss" scoped>
    .template {
        display:grid;
        align-items: center;   
        position: relative;            
        @media (min-width:700px) {
            grid-template-columns: 1fr 1fr;    
        }                        
    }    
    .text-content {
        background: linear-gradient(134.76deg, #202020 0%, #121212 100%);                
        -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
        color-adjust: exact;     
        box-sizing: border-box;
        color: #F5F5F5;
        display: grid;        
        height:100%;
        position: relative;
        @media (min-width:700px) {
            place-items: center;
        }  
        &:before {
            content:"";
            display:block;
            height:15px;
            width:100%;
            background-image:url('~@/assets/images/texture_black-asphalt.jpg');
            background-size: cover;
            position: absolute;
            top:0;
            left:0;
            -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
            color-adjust: exact;     
        }
        > div {
            &:not(.linear-holder) {
                padding:13px;
                padding-top:36px;
            }
        }
    }
    h1 {        
        color: #B6B6B6;
        text-transform: uppercase;
        padding-bottom:20px;
        border-bottom:1px solid #0066CC;
        max-width: 540px;
    }
    .copy {
        border-bottom:1px solid #0066CC;
        padding:20px 0;
        max-width: 540px;
         @media (min-width:700px) {
            max-height: calc(100vh - 350px);
            overflow: auto;
        }
    }
    .images {        
        display:grid;
        gap:1px;        
        height:100%; 
        min-height:50vh;
        overflow: hidden;       
        > div {
            background-size: cover;
            background-position: center;
            -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
            color-adjust: exact;     
            overflow: hidden;
            display: flex;
            flex-direction: column;            
        }
        &.three-images {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 50% 50%;
            > div {  
                position: relative;              
                &:first-child {
                    justify-content: flex-end;
                    grid-column-start: 1;
                    grid-column-end: 3;
                }
            }
        }             
    }
</style>